
import {
    UserOutlined,
    LockOutlined,
    SafetyCertificateOutlined,
    // QqOutlined,
    // WechatOutlined,
    // WeiboOutlined
} from '@ant-design/icons-vue';

export default {
    name: 'Login',
    components: {
        UserOutlined,
        LockOutlined,
        SafetyCertificateOutlined,
        // QqOutlined,
        // WechatOutlined,
        // WeiboOutlined
    },
    data() {
        return {
            // 登录框方向, 0居中, 1居右, 2居左
            direction: 0,
            // 加载状态
            loading: false,
            // 表单数据
            form: {
                username: '',
                password: '',
                verifycode: '',
                remember: true
            },
            // 表单验证规则
            rules: {
                username: [{
                    required: true,
                    message: this.$t('user.tips.username'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: this.$t('user.tips.password'),
                    type: 'string',
                    trigger: 'blur'
                }],
                verifycode: [{
                    required: true,
                    message: this.$t('user.tips.verify_code'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 验证码base64数据
            captcha: ''
        };
    },
    mounted() {
        if (this.$setting.takeToken()) {
            this.goHome();
        }
        this.changeCode();

        if (process.env.NODE_ENV === 'development') {
            this.form.username = 'admin';
            this.form.password = 'admin888';
        }

        if (process.env.VUE_APP_LOGIN_BG) {
            const logEl = document.getElementsByClassName("login-wrapper")[0];
            logEl.style['background-image'] = `url(${ require(`@/assets/${process.env.VUE_APP_LOGIN_BG}/bg-login.jpg`)})`;
        }
    },
    methods: {
        /* 提交 */
        doSubmit() {
            this.$refs.loginForm.validate().then(() => {
                this.loading = true;

                this.$http.post('/passport/authenticate', this.form).then((res) => {
                    this.loading = false;

                    if (res.data.code === 0) {
                        var token = res.data.data;

                        this.$message.success('user.tips.login_success');
                        this.$store.dispatch('user/setToken', {
                            token: `${token.token_type} ${token.access_token}`,
                            remember: this.form.remember
                        }).then(() => {
                            this.goHome();
                        });
                    } else {
                        this.$message.error(res.data.msg);
                        this.changeCode();
                    }
                }).catch((e) => {
                    this.loading = false;
                    this.$message.error(e.message || 'share.request_error');
                });
            }).catch(() => {});
        },
        /* 跳转到首页 */
        goHome() {
            if (this.$route.query && this.$route.query.from) {
                this.$router.push(String(this.$route.query.from));
            } else {
                this.$router.push('/').catch(() => {});
            }
        },
        /* 更换图形验证码 */
        changeCode() {
            this.$http.get('/passport/captcha').then(res => {
                if (res.data.code === 0) {
                    this.captcha = res.data.data.image;
                    this.form.verifycode = res.data.data.text;
                } else if (res.data.code == 10000) {
                    this.$router.push("install")
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        }
    }
}
